import fetch from 'unfetch';

export const getAllGames = () => 
    fetch('https://api.fog-games.com/api/games');
export const addNewGame = game =>
     fetch('https://api.fog-games.com/api/games', {
        headers: {
            'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(game)
     });