import React from "react";
import { Formik } from 'formik';
import { Input, Button, Tag } from 'antd';
import { addNewGame } from "../client";

const inputButtonMargin = { marginBottom: '5px' };
const tagStyle = { backgroundColor: '#f50', color: 'white', ...inputButtonMargin };

const AddGameForm = (props) => (
    <Formik
        initialValues={{
            title: ''
        }}
        validate={values => {
            const errors = {};
            if (!values.title) {
                errors.title = 'Title is required';
            }
            // else if (
            //     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.title)
            // ) {
            //     errors.title = 'Invalid email address';
            // }
            return errors;
        }}
        onSubmit={(game, { setSubmitting }) => {
            addNewGame(game).then(() => {
                props.onSuccess();
                setSubmitting(false);
            })
        }}
    >
        {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            submitForm,
            isValid
            /* and other goodies */
        }) => (
            <form onSubmit={handleSubmit}>
                <Input
                    style={inputButtonMargin}
                    name="title"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.title}
                    placeholder='Game Title'
                />
                {errors.title && touched.title &&
                    <Tag style={tagStyle}>{errors.title}</Tag>}
                <Button
                    onClick={() => submitForm()}
                    type="submit"
                    disabled={isSubmitting | (touched && !isValid)}>
                    Submit
                </Button>
            </form>
        )}
    </Formik>
);

export default AddGameForm;