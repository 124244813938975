import React from "react";
import './Header.css';
import logo from './img/logo.jpg';

function Header() {
    return (
        <div className='header'>
            <h1 className="title">𝕱𝖔𝖌 𝕲𝖆𝖒𝖊𝖘</h1>
            <img src={logo} className="logo" alt="logo"/>
        </div>
    )
};

export default Header;