import React from 'react';
import Clock from 'react-digital-clock';
import './ClockSet.css';  

function ClockSet(){
    return (
      <span>
           <Clock hour12= {false} />
      </span>
    );
  }
  export default ClockSet;